import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMapMarkedAlt,
  faMapMarkerAlt,
  faEnvelope,
  faGlobe,
  faCaretSquareLeft,
  faBars,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faMapMarkedAlt,
  faMapMarkerAlt,
  faEnvelope,
  faGlobe,
  faCaretSquareLeft,
  faBars,
  faCopy
);

import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { FontAwesomeIcon as FAI } from "@fortawesome/react-fontawesome";

import { Button } from "../button";
import { Popover } from "../popover";

const NavBar = (props) => {
  const { className, lang, metaData, langList } = props;
  const { navbar = {} } = metaData;
  const [isPositionTop, updatePositionTop] = useState(true);

  const _hashMove = (hash) => {
    const target = window.document.getElementById(hash);
    if (target) {
      window.scrollTo(0, Math.max(0, target.offsetTop));
    }
  };

  const _switchBackgroundColor = () => {
    return isPositionTop ? "navbar-ghost" : "navbar-solid";
  };

  const _redirectMove = (hash) => {
    if (window.location.hash !== `#${hash}`) {
      window.location.href = `${window.location.origin}/${lang}/#${hash}`;
      _hashMove(hash);
    }
  };
  const _redirectPage = (page) => {
    window.location.href = `${window.location.origin}/${lang}/${page}`;
  };
  const _switchLang = (langIndex) => {
    if (lang !== langIndex) {
      const pathname = window.location.pathname.split("/");
      pathname[1] = langIndex;
      window.location.href = `${window.location.origin}${pathname.join("/")}${
        window.location.hash
      }`;
    }
  };

  const _scrollPage = () => {
    if (window.pageYOffset === 0) {
      updatePositionTop(true);
    } else {
      updatePositionTop(false);
    }
  };

  useEffect(() => {
    const hash = window.location.hash.slice(1);
    _hashMove(hash);
    _scrollPage();
    window.addEventListener("scroll", _scrollPage);
  }, []);
  return (
    <header
      className={classnames([
        "layout_navbar",
        _switchBackgroundColor(),
        className,
      ])}
    >
      <div className="navbar_brand">
        <Button
          type="text"
          onClick={() => {
            _redirectMove("top");
          }}
        >
          {metaData.title}
        </Button>
      </div>
      <div className="navbar_buttons">
        <Button
          type="text"
          onClick={() => {
            _redirectMove("service");
          }}
        >
          {navbar.service}
        </Button>
        <Button
          type="text"
          onClick={() => {
            _redirectMove("about");
          }}
        >
          {navbar.about}
        </Button>
        <Button
          type="text"
          onClick={() => {
            _redirectMove("contact");
          }}
        >
          {navbar.contact}
        </Button>
        {/* <Button
          type="text"
          onClick={() => {
            _redirectPage("blog");
          }}
        >
          {navbar.blog}
        </Button> */}
        <Button
          type="text"
          onClick={() => {
            _redirectPage("jobs");
          }}
        >
          {navbar.jobs}
        </Button>
        <Popover
          className="navbar_langSelector"
          position="bottom"
          align="end"
          trigger={
            <Button type="text">
              <FAI icon={["fas", "globe"]} size="lg" fixedWidth />
            </Button>
          }
          withArrow={false}
        >
          {langList.map((lang) => {
            return (
              <Button
                className="langSelector_option"
                type="text"
                color="grey"
                fullSize
                onClick={() => {
                  _switchLang(lang.index);
                }}
                key={lang.index}
              >
                {lang.title}
              </Button>
            );
          })}
        </Popover>
      </div>
    </header>
  );
};

export default NavBar;

import React from "react";

import Helmet from "./Helmet";
import Navbar from "./Navbar";
import NavbarRwd from "./NavbarRwd";
import Footer from "./Footer";
import "../../style/index.scss";

const LANGUAGES = [
  { index: "en", title: "English" },
  { index: "zh", title: "繁體中文" },
];

const Layout = (props) => {
  const {
    children,
    lang = "zh",
    metaData = {},
    meta = [],
    pagename = "",
  } = props;
  return (
    <main className="layout">
      <Helmet lang={lang} metaData={metaData} pagename={pagename} meta={meta} />
      <Navbar lang={lang} metaData={metaData} langList={LANGUAGES} />
      <NavbarRwd lang={lang} metaData={metaData} langList={LANGUAGES} />
      <div>{children}</div>
      <Footer copyright={metaData.copyright} />
    </main>
  );
};

export default Layout;

import React from "react";
import classnames from "classnames";

/**
 * The module for clickable element.
 *
 * props = {
 *  children,
 *  className,
 *  justify, ['start', 'center', 'end', 'space-between', 'space-around']
 *  align, ['top', 'middle', 'bottom']
 *  spacing, [true, false]
 * }
 *
 * default: {
 *  spacing: true
 * }
 *
 * @param {*} props
 */

const Row = (props) => {
  const {
    children,
    className,
    justify,
    align,
    spacing = true,
    ...RowProps
  } = props;
  const classNameList = [
    justify ? `row-justify-${justify}` : "",
    align ? `row-align-${align}` : "",
    spacing ? `row-spacing` : "",
  ];

  return (
    <div
      className={classnames(
        "rvc-grid_row",
        "grid-row",
        className,
        classNameList
      )}
      {...RowProps}
    >
      {children}
    </div>
  );
};

export default Row;

import React, { useEffect, useState } from "react";
import { withPrefix } from "gatsby";
import classnames from "classnames";
import { FontAwesomeIcon as FAI } from "@fortawesome/react-fontawesome";

import { Button } from "../button";
import { Popover } from "../popover";

const NavBarRwd = (props) => {
  const { className, lang, metaData, langList } = props;
  const { navbar = {} } = metaData;
  const [, updatePositionTop] = useState(true);
  const [isMenuShowing, updateMenuShowingState] = useState(false);

  const _hashMove = (hash) => {
    const target = window.document.getElementById(hash);
    if (target) {
      window.scrollTo(0, Math.max(0, target.offsetTop));
    }
  };

  const _redirectMove = (hash) => {
    _toggleMenu(false);
    if (window.location.hash !== `#${hash}`) {
      window.location.href = `${window.location.origin}/${lang}/#${hash}`;
      _hashMove(hash);
    }
  };
  const _redirectPage = (page) => {
    window.location.href = `${window.location.origin}/${lang}/${page}`;
  };

  const _scrollPage = () => {
    if (window.pageYOffset === 0) {
      updatePositionTop(true);
    } else {
      updatePositionTop(false);
    }
  };
  const _switchLang = (langIndex) => {
    if (lang !== langIndex) {
      const pathname = window.location.pathname.split("/");
      pathname[1] = langIndex;
      window.location.href = `${window.location.origin}${pathname.join("/")}${
        window.location.hash
      }`;
    }
  };

  const _refreshPage = () => {
    window.location.reload();
  };
  const _toggleMenu = (turn = !isMenuShowing) => {
    if (turn) {
      document.body.classList.add("body-locked");
    } else {
      document.body.classList.remove("body-locked");
    }
    updateMenuShowingState(turn);
  };

  useEffect(() => {
    const hash = window.location.hash.slice(1);
    _hashMove(hash);
    _scrollPage();
    window.addEventListener("scroll", _scrollPage);
  }, []);
  return (
    <header className={classnames(["layout_navbar_rwd", className])}>
      <div
        aria-label="navbar_mask"
        className={classnames(["navbar_mask", { "mask-show": isMenuShowing }])}
        onClick={() => {
          _toggleMenu(false);
        }}
        onKeyDown={() => {
          _toggleMenu(false);
        }}
        role="button"
        tabIndex="0"
      />
      <div
        className={classnames(["navbar_menu", { "menu-show": isMenuShowing }])}
      >
        <Button
          className="menu_item"
          type="text"
          size="lg"
          fullSize
          onClick={() => {
            _redirectMove("service");
          }}
        >
          {navbar.service}
        </Button>
        <Button
          className="menu_item"
          type="text"
          size="lg"
          fullSize
          onClick={() => {
            _redirectMove("about");
          }}
        >
          {navbar.about}
        </Button>
        <Button
          className="menu_item"
          type="text"
          size="lg"
          fullSize
          onClick={() => {
            _redirectMove("contact");
          }}
        >
          {navbar.contact}
        </Button>
        {/* <Button
          className="menu_item"
          type="text"
          size="lg"
          fullSize
          onClick={() => {
            _redirectPage("blog");
          }}
        >
          {navbar.blog}
        </Button> */}
        <Button
          className="menu_item"
          type="text"
          size="lg"
          fullSize
          onClick={() => {
            _redirectPage("jobs");
          }}
        >
          {navbar.jobs}
        </Button>
        <div className="menu_language">
          <Popover
            className="navbar_langSelector"
            position="bottom"
            align="end"
            trigger={
              <Button className="language_opener" size="lg" type="text">
                <FAI icon={["fas", "globe"]} size="lg" fixedWidth />
              </Button>
            }
            withArrow={false}
          >
            {langList.map((lang) => {
              return (
                <Button
                  className="langSelector_option"
                  type="text"
                  size="lg"
                  fullSize
                  onClick={() => {
                    _switchLang(lang.index);
                  }}
                  key={lang.index}
                >
                  {lang.title}
                </Button>
              );
            })}
          </Popover>
        </div>
      </div>
      <div className="navbar_brand">
        <Button
          type="text"
          onClick={() => {
            _refreshPage("top");
          }}
        >
          <img
            className="brand_logo"
            src={`${withPrefix("/")}img/ravel-logo-sm.png`}
            alt="logo"
          />
        </Button>
      </div>
      <div className="navbar_toggle">
        <Button
          size="sm"
          type="text"
          onClick={() => {
            _toggleMenu();
          }}
        >
          <FAI icon={["fas", "bars"]} size="2x" fixedWidth />
        </Button>
      </div>
    </header>
  );
};

export default NavBarRwd;

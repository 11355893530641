import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";

const useActiveState = (defaultSate = false) => {
  const [value, setState] = useState(defaultSate);
  return {
    value,
    onToggle: (state) => {
      if (typeof state == "boolean") {
        setState(state);
      } else {
        setState(!value);
      }
    },
  };
};

const useAdjustState = (defaultSate = { position: "", align: "" }) => {
  const [value, setState] = useState(defaultSate);
  return {
    value,
    onAdjust: (state) => {
      setState(state);
    },
  };
};

const Popover = (props) => {
  const {
    showState,
    withArrow = true,
    position = "bottom",
    align = "begin",
  } = props;
  const activeState = useActiveState(showState);
  const autoAdjust = useAdjustState();

  const ref_trigger = useRef(null);
  const ref_content = useRef(null);
  const computed_position = autoAdjust.value.position || position;
  const computed_align = autoAdjust.value.align || align;
  const index = `${Date.now()}-${Math.ceil(Math.random() * 1000)}`;

  function _clickListener(event) {
    let result = event.composedPath().find((node) => {
      if (node.classList) {
        return node.classList.value.search(`popover-${index}`) > 0;
      }
      return false;
    });
    if (typeof result == "undefined") {
      _togglePopover();
    }
  }

  /* eslint-disable react-hooks/exhaustive-deps  */
  useEffect(() => {
    if (typeof showState != "undefined") {
      if (activeState.value !== showState) {
        activeState.onToggle(showState);
      }
    }
  }, [showState]);

  useEffect(() => {
    window.removeEventListener("click", _clickListener);
    if (activeState.value) {
      window.addEventListener("click", _clickListener);
    }
    return function cleanup() {
      window.removeEventListener("click", _clickListener);
    };
  }, [activeState.value]);
  /* eslint-enable react-hooks/exhaustive-deps  */

  function _togglePopover() {
    if (typeof showState === "undefined") {
      if (typeof props.onToggle != "undefined") {
        props.onToggle(!activeState.value);
      }
      if (activeState.value) {
        if (typeof props.onHide != "undefined") {
          props.onHide();
        }
      } else {
        if (typeof props.onShow != "undefined") {
          props.onShow();
        }
      }
      activeState.onToggle();
    }
  }

  return (
    <div
      className={classnames(
        "rvc-popover",
        `popover-${index}`,
        props.className,
        `popover-align-${computed_align}`,
        { "popover-arrow": withArrow }
      )}
    >
      <div
        ref={ref_trigger}
        className="popover_trigger"
        onClick={_togglePopover}
        onKeyDown={_togglePopover}
        role="button"
        tabIndex="0"
      >
        {typeof props.trigger != "undefined" ? props.trigger : "Trigger"}
      </div>
      <div
        ref={ref_content}
        className={classnames(
          "popover_content",
          { "content-show": activeState.value },
          `content-position-${computed_position}`
        )}
      >
        {props.children || "Empty"}
      </div>
    </div>
  );
};

export default Popover;

import React from "react";
import classnames from "classnames";

/**
 * The module for clickable element.
 *
 * props = {
 *  children,
 *  className,
 *
 *  // value: 1-12
 *  col,
 *  xs,
 *  sm,
 *  md,
 *  lg,
 *  xl,
 *  xxl,
 *  colOffset,
 *  xsOffset,
 *  smOffset,
 *  mdOffset,
 *  lgOffset,
 *  xlOffset,
 *  xxlOffset,
 *
 *  // value: [true, false]
 *  colWrap,
 *  xsWrap,
 *  smWrap,
 *  mdWrap,
 *  lgWrap,
 *  xlWrap,
 *  spacing,
 * }
 *
 * default = {
 *  spacing: true
 * }
 *
 * @param {*} props
 */

const Col = (props) => {
  const {
    children,
    className,
    col,
    xs,
    sm,
    md,
    lg,
    xl,
    colOffset,
    xsOffset,
    smOffset,
    mdOffset,
    lgOffset,
    xlOffset,
    colWrap,
    xsWrap,
    smWrap,
    mdWrap,
    lgWrap,
    xlWrap,
    spacing = true,
    ...ColProps
  } = props;
  const classNameList = [
    typeof col !== "undefined" ? `col-${col}` : "",
    typeof colOffset !== "undefined" ? `col-offset-${colOffset}` : "",
    typeof colWrap !== "undefined" ? "col-wrap" : "",
    typeof xs !== "undefined" ? `col-xs-${xs}` : "",
    typeof xsOffset !== "undefined" ? `col-xs-offset-${xsOffset}` : "",
    typeof xsWrap !== "undefined" ? "col-xs-wrap" : "",
    typeof sm !== "undefined" ? `col-sm-${sm}` : "",
    typeof smOffset !== "undefined" ? `col-sm-offset-${smOffset}` : "",
    typeof smWrap !== "undefined" ? "col-sm-wrap" : "",
    typeof md !== "undefined" ? `col-md-${md}` : "",
    typeof mdOffset !== "undefined" ? `col-md-offset-${mdOffset}` : "",
    typeof mdWrap !== "undefined" ? "col-md-wrap" : "",
    typeof lg !== "undefined" ? `col-lg-${lg}` : "",
    typeof lgOffset !== "undefined" ? `col-lg-offset-${lgOffset}` : "",
    typeof lgWrap !== "undefined" ? "col-lg-wrap" : "",
    typeof xl !== "undefined" ? `col-xl-${xl}` : "",
    typeof xlOffset !== "undefined" ? `col-xl-offset-${xlOffset}` : "",
    typeof xlWrap !== "undefined" ? "col-xl-wrap" : "",
    typeof spacing !== "undefined" ? `col-spacing` : "",
  ];

  return (
    <div
      className={classnames(
        "rvc-grid_col",
        "row_col",
        className,
        classNameList
      )}
      {...ColProps}
    >
      {children}
    </div>
  );
};

export default Col;

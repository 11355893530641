import React from "react";

const Footer = (props) => {
  const { copyright } = props;
  return (
    <div className="layout_footer">
      <p className="footer_copyright">{copyright}</p>
    </div>
  );
};

export default Footer;

import React from "react";
import { Helmet } from "react-helmet";

const LayoutHelmet = (props) => {
  const { lang, pagename, metaData, meta } = props;

  const _htmlAttr = {
    lang,
  };
  const _meta = [
    {
      name: `description`,
      content: metaData.description,
    },
    {
      property: `og:title`,
      content: metaData.title,
    },
    {
      property: `og:description`,
      content: metaData.description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: metaData.author,
    },
    {
      name: `twitter:title`,
      content: metaData.title,
    },
    {
      name: `twitter:description`,
      content: metaData.description,
    },
  ].concat(meta);
  return (
    <Helmet
      htmlAttributes={_htmlAttr}
      title={`${pagename} | ${metaData.title}`}
      meta={_meta}
    />
  );
};

export default LayoutHelmet;

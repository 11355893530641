import React from "react";
import classnames from "classnames";

const Button = (props) => {
  const {
    children,
    className,
    type = "solid", // solid, text, link, tag
    color = "primary", // refer to button_colorLists
    size = "md", // refer to button_sizeList
    iconLeft,
    iconRight,
    fullSize = false,
    highlight = false,
    ...buttonProps
  } = props;
  const classNameList = [
    `button-type-${type}`,
    `button-color-${color}`,
    `button-size-${size}`,
    { "button-full-size": fullSize },
    { "button-highlight": highlight },
  ];
  return (
    <button
      type="button"
      className={classnames("rvc-button", className, classNameList)}
      {...buttonProps}
    >
      {iconLeft ? (
        <div className="button_item item-icon icon-left">{iconLeft}</div>
      ) : (
        []
      )}
      <div className="button_item">{children}</div>
      {iconRight ? (
        <div className="button_item item-icon icon-right">{iconRight}</div>
      ) : (
        []
      )}
    </button>
  );
};

export default Button;
